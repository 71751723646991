.cargill-okta-widget #okta-sign-in {
  max-width: 90vw;
  margin-top: 0px;
}

.cargill-okta-widget #okta-sign-in {
  animation: fadein ease 0.25s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cargill-okta-widget.fade {
  transition: all 0.3s;
  opacity: 0;
}
.cargill-okta-widget.fade:has(#okta-sign-in) {
  opacity: 1;
}

.cargill-okta-widget #okta-sign-in.auth-container.main-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 448px;
  box-shadow: 0 0 80px -10px #191919;
}

.cargill-okta-widget .okta-sign-in-header {
  display: none;
}

.cargill-okta-widget .okta-form-title {
  display: none;
}
.cargill-okta-widget .forgot-password .okta-form-title {
  display: block;
}

.cargill-okta-widget #okta-sign-in .o-form-control {
  height: 48px;
  border: 2px solid #999;
  padding-left: 10px;
}

.cargill-okta-widget #okta-sign-in .o-form-control.focused-input {
  border: 2px solid #0074b3;
}

.cargill-okta-widget #okta-sign-in .focused-input,
.cargill-okta-widget #okta-sign-in .link.help:focus,
.cargill-okta-widget #okta-sign-in input[type="radio"]:focus + label,
.cargill-okta-widget #okta-sign-in input[type="submit"]:focus,
.cargill-okta-widget #okta-sign-in input[type="text"]:focus {
  box-shadow: none;
}

.cargill-okta-widget #okta-sign-in .focused-input {
  box-shadow: 0 0 8px color-mix(in srgb, #007582, #fff 25%);
}
.cargill-okta-widget #okta-sign-in .focused-input.custom-checkbox {
  outline: 2px solid #0074b3;
  box-shadow: none;
}

.cargill-okta-widget #okta-sign-in .primary-auth-form .button-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  cursor: pointer;
  height: 56px;
  border: 0;
  border-radius: 0;
  font-weight: 700;
  font-size: 16px;

  /* todo: this background color fails with WCAG AAA */
  background: #007582;
  color: #fefefe;

  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15),
    inset 0 1px 0 0 hsla(0, 0%, 100%, 0.1);

  transition: all 200ms;
}
.cargill-okta-widget #okta-sign-in .primary-auth-form .button-primary:hover {
  background: color-mix(in srgb, #007582, #000 25%);
  color: #fff;
}

.cargill-okta-widget #okta-sign-in .o-form-button-bar {
  margin: 0;
  padding-bottom: 0;
}
.cargill-okta-widget #okta-sign-in .o-form-button-bar.focused-input {
  padding-bottom: 0;
  margin: 0;
}

.cargill-okta-widget #okta-sign-in .o-form-input-name-remember label {
  color: #000;
  font-size: 14px;
}

.cargill-okta-widget .auth-divider {
  display: none;
}

.cargill-okta-widget
  #okta-sign-in
  .social-auth-general-idp-button.cargill-identity {
  height: 40px;
  display: flex;
  background: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 14px;
  color: #555;
  border: 1px solid #cbcbcb;
  transition: all 200ms;
  font-weight: bold;
}
.cargill-okta-widget
  #okta-sign-in
  .social-auth-general-idp-button.cargill-identity:hover {
  color: #222;
  border: 1px solid #777;
}
.cargill-okta-widget
  #okta-sign-in
  .social-auth-general-idp-button.cargill-identity:focus {
  outline: 2px solid #0074b3;
}

.cargill-okta-widget #okta-sign-in .auth-footer {
  text-align: center;
}
.cargill-okta-widget #okta-sign-in .auth-footer .link.help {
  display: block;
  font-weight: 700;
  padding: 0.5rem;
  color: #000;
}
.cargill-okta-widget #okta-sign-in .auth-footer .link.help:focus {
  outline: 2px solid #0074b3;
}
.cargill-okta-widget #okta-sign-in .help-links.js-help-links {
  text-align: left;
  font-weight: bold;
}

#okta-sign-in .request-access-flow.link-button:last-of-type {
  margin-bottom: 5px;
}

#okta-sign-in.auth-container.main-container .margin-btm-30 {
  margin-bottom: 25px;
}

.cargill-okta-widget #okta-sign-in .default-custom-button.request-access-flow {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #007582;
  font-size: 14px;
  border-radius: 3px;
  font-weight: 700;
  text-decoration: none;
  background-color: transparent;
  border: none;
  line-height: 1;
}
.cargill-okta-widget
  #okta-sign-in
  .default-custom-button.request-access-flow:hover {
  color: color-mix(in srgb, #007582, #000 25%);
}
.cargill-okta-widget
  #okta-sign-in
  .default-custom-button.request-access-flow:focus {
  outline: 2px solid #0074b3;
}
